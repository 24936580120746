/* eslint-disable react/button-has-type */
import React from 'react';
import s from './Filter.scss';

import {FilterType} from '../../../types/galleryTypes';
import {Minus, Plus} from '../../../icons/dist';
import AnimateHeight from 'react-animate-height';
import classNames from 'classnames';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';

export enum DataHook {
  ExpandIcon = 'filter-expand-icon',
  FullTitle = 'filter-full-title',
  OptionName = 'filter-option-name',
  Title = 'filter-title',
  TitleWrapper = 'filter-title-wrapper',
}

export interface IFilterProps extends IGalleryGlobalProps {
  children: React.ReactChild;
  expanded: boolean;
  filterType: FilterType;
  title: string;
}

export interface IFilterState {
  expanded: boolean;
  optionName?: string;
}

class FilterComp extends React.Component<IFilterProps, IFilterState> {
  constructor(props) {
    super(props);
    this.state = {
      expanded: props.expanded,
      optionName: '',
    };
  }

  public render() {
    const {expanded, optionName} = this.state;

    return (
      <>
        <button
          onClick={() => this.toggleExpand()}
          className={s.header}
          data-hook={DataHook.TitleWrapper}
          aria-expanded={expanded}>
          <span className={s.title} data-hook={DataHook.FullTitle}>
            <span data-hook={DataHook.Title}>{this.props.title}</span>
            {optionName && <span data-hook={DataHook.OptionName}>: {optionName}</span>}
          </span>
          <span data-hook={DataHook.ExpandIcon} className={s.expandIcon}>
            {expanded ? <Minus /> : <Plus />}
          </span>
        </button>
        <AnimateHeight easing={'cubic-bezier(.39,.11,.37,.99)'} duration={200} height={expanded ? 'auto' : 0}>
          <div className={classNames(s.content, {[s.expanded]: expanded})}>{this.renderChild()}</div>
        </AnimateHeight>
      </>
    );
  }

  private toggleExpand() {
    const nextState = {expanded: !this.state.expanded};
    /* istanbul ignore next: hard to test it */
    this.setState(nextState, () => {
      this.props.globals.updateLayout && this.props.globals.updateLayout();
    });
  }

  private readonly renderChild = () => {
    if (this.props.filterType !== FilterType.COLOR_OPTION) {
      return this.props.children;
    }

    return React.cloneElement(this.props.children as React.ReactElement, {
      showOptionName: (optionName) => !this.props.globals.shouldShowMobile && this.setState({optionName}),
    });
  };
}

export const Filter = withGlobals(FilterComp);
