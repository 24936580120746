import React from 'react';
import s from './Ribbon.scss';
import {DataHook} from '../ProductItem';
import {RibbonPlacementId, RibbonType} from '../../../../types/galleryTypes';
import classNames from 'classnames';
import {withTranslations} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';

export interface IRibbonProps {
  ribbon: string;
  placement: RibbonPlacementId;
  type: RibbonType;
  shouldShowMobile: boolean;
  id: string;
}

class RibbonComp extends React.Component<IRibbonProps> {
  public render() {
    const {ribbon, placement, type, shouldShowMobile, id} = this.props;
    return (
      <span
        id={id}
        className={classNames(
          s.ribbon,
          {[s.onImage]: placement === RibbonPlacementId.OnImage},
          {[s.productInfo]: placement === RibbonPlacementId.ProductInfo},
          {[s.rectangle]: type === RibbonType.RECTANGLE},
          {[s.mobile]: shouldShowMobile}
        )}
        data-hook={DataHook.Ribbon}>
        {ribbon}
      </span>
    );
  }
}

export const ProductRibbon = withTranslations()(RibbonComp);
