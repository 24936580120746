import * as React from 'react';
import {CSSProperties, RefObject, ReactNode} from 'react';
import s from '../Carousel.scss';
import {Arrow, ArrowDirection} from './Arrow/Arrow';
import {CarouselAriaLabels, TRANSITION_TIME} from '../Carousel';
import classNames from 'classnames';

interface CarouselDesktopState {
  style?: CSSProperties;
  items?: ReactNode[];
}

export enum CarouselDesktopDataHooks {
  PreviousArrow = 'left-arrow',
  NextArrow = 'right-arrow',
  Carousel = 'carousel',
  CarouselDesktop = 'carouselWrapper',
}

export interface CarouselDesktopProps {
  shouldShowArrowsOnHover: boolean;
  ariaLabels: CarouselAriaLabels;
}

export class CarouselDesktop extends React.Component<CarouselDesktopProps, CarouselDesktopState> {
  private readonly ref: RefObject<any>;
  private isElementsOverFlow: boolean;

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.isElementsOverFlow = false;
    this.state = {
      style: {},
      items: React.Children.toArray(this.props.children),
    };
  }

  private readonly containerWidth = () => {
    return this.ref.current?.getBoundingClientRect().width;
  };

  private readonly spinLeft = (_itemsToSlide: number) => {
    const style: CSSProperties = {};
    style.transition = `transform ${TRANSITION_TIME}ms ease-in-out`;
    style.transform = `translateX(0)`;

    this.isElementsOverFlow = false;

    return this.setState({style});
  };

  private readonly spinRight = (_itemsToSlide: number) => {
    const style: CSSProperties = {};
    style.transition = `transform ${TRANSITION_TIME}ms ease-in-out`;
    style.transform = `translateX(${-this.containerWidth() * _itemsToSlide}px)`;
    this.isElementsOverFlow = true;

    this.setState({style});
  };

  private readonly onNext = (itemsToSlide = 1) => {
    const nextItems = this.state.items;

    if (this.isElementsOverFlow) {
      const style: CSSProperties = {};
      style.transition = '';
      style.transform = `translateX(0)`;
      const firstItem = nextItems.shift();
      nextItems.push(firstItem);
      this.isElementsOverFlow = false;
      this.setState({style, items: nextItems}, () => this.spinRight(itemsToSlide));
    } else {
      this.spinRight(itemsToSlide);
    }
  };

  private readonly onPrevious = (itemsToSlide = 1) => {
    if (this.isElementsOverFlow) {
      this.spinLeft(itemsToSlide);
    } else {
      const style: CSSProperties = {};
      const nextItems = this.state.items;

      style.transition = '';
      style.transform = `translateX(${-this.containerWidth() * itemsToSlide}px)`;
      const firstItem = nextItems.pop();
      nextItems.unshift(firstItem);
      this.isElementsOverFlow = true;
      this.setState({style, items: nextItems});
      setTimeout(() => this.spinLeft(itemsToSlide), 0);
    }
  };

  public render() {
    const {style, items} = this.state;
    const {shouldShowArrowsOnHover, ariaLabels} = this.props;

    return (
      <div
        ref={this.ref}
        data-hook={CarouselDesktopDataHooks.CarouselDesktop}
        className={classNames(s.carouselWrapper, shouldShowArrowsOnHover ? s.showArrowsOnHover : '')}>
        <Arrow
          ariaLabel={ariaLabels.arrowLeft}
          dataHook={CarouselDesktopDataHooks.PreviousArrow}
          onClick={this.onPrevious}
          onKeyPress={this.onPrevious}
          direction={ArrowDirection.LEFT}
          className={s.arrow}
        />
        <div className={s.carousel} data-hook={CarouselDesktopDataHooks.Carousel} style={style}>
          {items}
        </div>
        <Arrow
          ariaLabel={ariaLabels.arrowRight}
          dataHook={CarouselDesktopDataHooks.NextArrow}
          onClick={this.onNext}
          onKeyPress={this.onNext}
          direction={ArrowDirection.RIGHT}
          className={s.arrow}
        />
      </div>
    );
  }
}
