/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import * as React from 'react';
import {CSSProperties, ReactNode, RefObject} from 'react';
import {DotNavigation, DotNavigationTheme} from 'wix-ui-tpa';
import * as s from '../Carousel.scss';
import {classes as dotsStylable} from './CarouselMobile.st.css';
import * as sm from './CarouselMobile.scss';
import classNames from 'classnames';
import {TRANSITION_TIME} from '../Carousel';

export const EDGE_GAP_PERCENTAGE = 0.3;
export const REGULAR_GAP_PERCENTAGE = 0.6;

interface MobileCarouselState {
  style?: CSSProperties;
  items?: ReactNode[];
  curIndex?: number;
}

export enum MobileCarouselDataHooks {
  MobileCarousel = 'carousel-mobile',
  MobileCarouselContainer = 'carousel-mobile-container',
  Dots = 'carousel-mobile-dots',
}

export class CarouselMobile extends React.Component<{}, MobileCarouselState> {
  private longTouch: boolean;
  private readonly ref: RefObject<any>;
  private isElementsOverFlow: boolean;

  constructor(props) {
    super(props);
    this.longTouch = false;
    this.ref = React.createRef();
    this.state = {
      style: {},
      items: React.Children.toArray(this.props.children),
      curIndex: 0,
    };
  }

  private readonly containerWidth = () => {
    return this.ref.current?.getBoundingClientRect().width;
  };

  private readonly spinLeft = (_itemsToSlide: number) => {
    const style: CSSProperties = {};
    style.transition = `transform ${TRANSITION_TIME}ms ease-in-out`;
    style.transform = `translateX(0)`;

    this.isElementsOverFlow = false;

    return this.setState({style});
  };

  private readonly spinRight = (_itemsToSlide: number) => {
    const style: CSSProperties = {};
    style.transition = `transform ${TRANSITION_TIME}ms ease-in-out`;
    style.transform = `translateX(${-this.containerWidth() * _itemsToSlide}px)`;
    this.isElementsOverFlow = true;

    this.setState({style});
  };

  /* istanbul ignore next: will be refactored */
  private readonly onNext = (itemsToSlide = 1) => {
    const nextItems = this.state.items;

    if (this.isElementsOverFlow) {
      const style: CSSProperties = {};
      style.transition = '';
      style.transform = `translateX(0)`;
      const firstItem = nextItems.shift();
      nextItems.push(firstItem);
      this.isElementsOverFlow = false;
      this.setState({style, items: nextItems}, () => this.spinRight(itemsToSlide));
    } else {
      this.spinRight(itemsToSlide);
    }
  };
  /* istanbul ignore next: will be refactored */
  private readonly onPrevious = (itemsToSlide = 1) => {
    if (this.isElementsOverFlow) {
      this.spinLeft(itemsToSlide);
    } else {
      const style: CSSProperties = {};
      const nextItems = this.state.items;

      style.transition = '';
      style.transform = `translateX(${-this.containerWidth() * itemsToSlide}px)`;
      const firstItem = nextItems.pop();
      nextItems.unshift(firstItem);
      this.isElementsOverFlow = true;
      this.setState({style, items: nextItems});
      setTimeout(() => this.spinLeft(itemsToSlide), 0);
    }
  };

  /* istanbul ignore next: test in sled */
  public readonly onTouchStart = (event) => {
    // Test for flick.
    this.longTouch = false;
    setTimeout(() => {
      this.longTouch = true;
    }, 250);
    // Get the original touch position.
    this.ref.current.touchstartx = event.touches[0].pageX;
  };

  /* istanbul ignore next: test in sled */
  public readonly onTouchMove = (event) => {
    const style: CSSProperties = {};

    const {curIndex, items} = this.state;
    let nextTranslate;
    // Continuously return touch position.
    this.ref.current.touchmovex = event.changedTouches[0].pageX;

    // Calculate distance to translate holder - according to max gap.
    if (this.ref.current.touchstartx - this.ref.current.touchmovex < 0) {
      this.ref.current.movex = Math.min(
        -(this.ref.current.touchstartx - this.ref.current.touchmovex),
        this.containerWidth() * (curIndex === 0 ? EDGE_GAP_PERCENTAGE : REGULAR_GAP_PERCENTAGE)
      );
    } else {
      this.ref.current.movex = -Math.min(
        this.ref.current.touchstartx - this.ref.current.touchmovex,
        this.containerWidth() * (curIndex === items.length - 1 ? EDGE_GAP_PERCENTAGE : REGULAR_GAP_PERCENTAGE)
      );
    }
    const updatedMovexValue = parseFloat(this.ref.current.movex);
    // Defines the speed the images should move at.
    style.transition = `transform ${TRANSITION_TIME}ms ease-in-out`;

    // Calc css TranslateX according to  current movex
    if (updatedMovexValue > 0) {
      nextTranslate = curIndex === 0 ? updatedMovexValue : updatedMovexValue - curIndex * this.containerWidth();
    } else {
      nextTranslate = curIndex * -this.containerWidth() + updatedMovexValue;
    }

    style.transform = `translateX(${nextTranslate}px)`;
    this.setState({style});
  };

  /* istanbul ignore next: test in sled */
  public readonly onTouchEnd = (_event) => {
    const {curIndex, items} = this.state;
    const style: CSSProperties = {};
    const curMovexValue = parseFloat(this.ref.current.movex);
    let nextIndex;
    // Calculate the distance swiped.
    const absMove = Math.abs(curIndex * this.containerWidth() - curMovexValue);
    // Calculate the index. All other calculations are based on the index.
    if (absMove > this.containerWidth() * 0.2 || this.longTouch === false) {
      if (curMovexValue >= 0) {
        if (curIndex > 0) {
          nextIndex = curIndex - 1;
        } else {
          nextIndex = 0;
        }
      } else if (curIndex < items.length - 1) {
        nextIndex = curIndex + 1;
      } else {
        nextIndex = items.length - 1;
      }
    } else {
      nextIndex = curIndex;
    }

    style.transition = `transform ${TRANSITION_TIME}ms ease-in-out`;

    // Move and animate the elements.
    style.transform = `translateX(${-nextIndex * this.containerWidth()}px)`;

    this.setState({style, curIndex: nextIndex});
  };

  public stopPropagation = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  public onSelect = (index: number) => {
    const {curIndex} = this.state;
    if (curIndex < index) {
      this.onNext(index - curIndex);
    } else {
      this.onPrevious(curIndex - index);
    }

    this.setState({
      curIndex: index,
    });
  };

  public render() {
    const {items, style, curIndex} = this.state;
    const carouselClasses = classNames(s.carousel, {[sm.msTouch]: navigator.maxTouchPoints});
    const dotsClasses = classNames(dotsStylable.root, sm.dots);

    return (
      <div ref={this.ref} className={s.carouselWrapper} data-hook={MobileCarouselDataHooks.MobileCarouselContainer}>
        <div
          className={carouselClasses}
          onTouchStart={this.onTouchStart}
          onTouchMove={this.onTouchMove}
          onTouchEnd={this.onTouchEnd}
          data-hook={MobileCarouselDataHooks.MobileCarousel}
          style={style}>
          {items}
        </div>
        <div role="navigation" className={dotsClasses} onClick={this.stopPropagation}>
          <DotNavigation
            aria-label={curIndex.toString()}
            data-hook={MobileCarouselDataHooks.Dots}
            theme={DotNavigationTheme.Dark}
            currentIndex={curIndex}
            length={items.length}
            onSelect={this.onSelect}
          />
        </div>
      </div>
    );
  }
}
